import React from 'react';
import { Global } from '@theme';
import { Layout } from '@components';
import { SEO } from '@components';
import {
  InitialSection,
  ContactSection,
  SolutionsSection,
  StepsSection,
  TechnologiesSection,
  DifferentialsSection,
  CasesSection,
} from '@screens/Main';

const TitlePage2 = () => (
  <>
    <Global />
    <Layout>
      <SEO title="Criação de software, sob medida e sem complicação - Diagnóstico Projeto" />
      <InitialSection text="Precisa de apoio para a criação do software da sua empresa? Vamos te dar help para tirar sua ideia do papel!"/>
      <SolutionsSection />
      <StepsSection />
      <TechnologiesSection />
      <CasesSection />
      <DifferentialsSection />
      <ContactSection />
    </Layout>
  </>
);

export default TitlePage2;
